<template>
    <v-main>
        <template v-slot:sidebar>
            <v-section-heading>
                <template v-slot:default>
                    Filters
                </template>

                <template v-slot:actions>
                    <div v-if="hasFilters">
                        <v-link color="primary" @click="clearFilters()">
                            Clear
                        </v-link>
                    </div>
                </template>
            </v-section-heading>

            <v-payout-table-filters ref="payoutTableFilters" v-model="filters" />
        </template>

        <template v-slot:default="{ toggleSidebar }">
            <v-section-heading>
                <template v-slot:default>
                    Payouts
                </template>

                <template v-slot:description>
                    A list of all the payouts in the application.
                </template>

                <template v-slot:actions>
                    <div class="w-full grid grid-cols-2 sm:grid-cols-1 gap-2 sm:place-items-end">
                        <div class="block lg:hidden">
                            <v-button class="w-full" color="primary" @click.stop="toggleSidebar">
                                <i class="fa fa-filter mr-2" />
                                Filter Results
                            </v-button>
                        </div>

                        <div>
                            <v-button v-if="$me.hasPermission('payouts.create')" :to="{ name: 'payouts.create' }" class="order-last w-full sm:w-auto" color="primary" text-alignment="center">
                                Create Payout
                            </v-button>
                        </div>
                    </div>
                </template>
            </v-section-heading>

            <v-payout-table :query="filters" />
        </template>
    </v-main>
</template>

<script>
import PayoutTable from "@/components/application/payout/payout-table.vue";
import PayoutTableFilters from "@/components/application/payout/payout-table-filters.vue";
import Main from "@/layouts/panel/main.vue";

export default {
    components: {
        "v-payout-table": PayoutTable,
        "v-payout-table-filters": PayoutTableFilters,
        "v-main": Main,
    },
    data() {
        return {
            /**
             * @todo Write a description.
             */
            filters: {},
        };
    },
    computed: {
        hasFilters() {
            return this.parseFilters(this.filters).length;
        },
    },
    created() {
        // Filter payouts by the query string parameters on initial page load.
        this.filters = {
            ...this.filters,
            ...this.$route.query,
        };
    },
    methods: {
        clearFilters() {
            this.$refs.payoutTableFilters.clearFilters();
        },
    },
};
</script>
