<template>
    <div class="w-full h-full flex overflow-hidden divide-x-0 lg:divide-x-2 divide-light">
        <template v-if="hasSidebarSlot">
            <v-section-group class="w-96 h-full hidden lg:block overflow-y-auto bg-default">
                <slot name="sidebar" />
            </v-section-group>
            <!-- Mobile View -->
            <div class="lg:hidden">
                <transition
                    enter-active-class="transition-opacity ease-linear duration-300"
                    enter-class="opacity-0"
                    enter-to-class="opacity-100"
                    leave-active-class="transition-opacity ease-linear duration-300"
                    leave-class="opacity-100"
                    leave-to-class="opacity-0"
                >
                    <div v-if="showSidebar" class="absolute inset-0 flex z-40" @click="showSidebar = false">
                        <div class="absolute inset-0 bg-black opacity-50" />
                    </div>
                </transition>

                <transition
                    enter-active-class="transition ease-in-out duration-300 transform"
                    enter-class="-translate-x-full"
                    enter-to-class="translate-x-0"
                    leave-active-class="transition ease-in-out duration-300 transform"
                    leave-class="translate-x-0"
                    leave-to-class="-translate-x-full"
                >
                    <div v-if="showSidebar" class="w-64 absolute top-0 h-full z-40 bg-default overflow-y-auto">
                        <div class="relative h-full">
                            <v-button color="transparent" class="absolute right-0 top-2" @click="showSidebar = false">
                                <v-icon class="fa fa-times" />
                            </v-button>

                            <slot name="sidebar" />
                        </div>
                    </div>
                </transition>
            </div>
        </template>

        <template v-if="hasDefaultSlot">
            <div class="w-full h-full">
                <v-section class="w-full h-full max-h-full flex flex-col overflow-y-auto bg-default">
                    <slot :toggle-sidebar="toggleSidebar" />
                </v-section>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: "LayoutsPanelMain",
    data() {
        return {
            showSidebar: false,
        };
    },
    computed: {
        hasDefaultSlot() {
            return (!!this.$slots.default || !!this.$scopedSlots.default);
        },
        hasSidebarSlot() {
            return (!!this.$slots.sidebar || !!this.$scopedSlots.sidebar);
        },
    },
    watch: {
        "$route.name": {
            handler(to, from) {
                if (from && (to !== from)) {
                    this.closeSidebar();
                }
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        toggleSidebar() {
            this.showSidebar = !this.showSidebar;
        },
        closeSidebar() {
            this.showSidebar = false;
        },
    },
};
</script>
