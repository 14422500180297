/**
 * @todo Write a description.
 */

import _ from "lodash";

export default {
    props: {
        value: {
            type: Object,
            required: false,
            default: () => ({}),
        },
    },
    watch: {
        /**
         * @todo Write a description.
         */
        value: {
            handler(currentValue) {
                const mergedValue = {
                    ...this.filters,
                    ...currentValue,
                };

                if (!this.isEqualObject(currentValue, mergedValue)) {
                    this.filters = mergedValue;
                }
            },
            immediate: true,
            deep: true,
        },

        /**
         * @todo Write a description.
         */
        filters: {
            handler(value) {
                const pickedValue = _.pickBy({
                    ...this.$route.query,
                    ...value,
                }, (item) => {
                    if (Array.isArray(item) && !item.length) {
                        return false;
                    }

                    if (typeof item === "boolean") {
                        return true;
                    }

                    return !!item;
                });

                if (!this.isEqualObject(this.$route.query, pickedValue)) {
                    this.$emit("input", value);
                    this.$router.replace({ query: pickedValue });
                }
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        /**
         * @todo Write a description.
         */
        parseQueryString(str = null) {
            if (!str || !str.length) {
                return [];
            }

            if (Array.isArray(str)) {
                return str;
            }

            return [str];
        },

        /**
         * @todo Write a description.
         */
        selectOptions(data, labelField, valueField) {
            if (data) {
                return data.map((item) => ({ label: item[labelField], value: item[valueField] }));
            }
            return null;
        },

        /**
         * @todo Write a description.
         */
        sortObjectKeys(object) {
            return _.sortBy(Object.keys(object)).map((key) => (object[key]));
        },

        /**
         * @todo Write a description.
         */
        isEqualObject(objectA = {}, objectB = {}) {
            objectA = this.stringifyObjectValues(objectA);
            objectB = this.stringifyObjectValues(objectB);

            return (JSON.stringify(this.sortObjectKeys(objectA)) === JSON.stringify(this.sortObjectKeys(objectB)));
        },

        /**
         * @todo Write a description.
         */
        stringifyObjectValues(object) {
            return _.transform(object, (result, value, key) => {
                result[key] = value;

                if (typeof value === "number" || typeof value === "boolean") {
                    result[key] = `${value}`;
                }

                if (Array.isArray(value)) {
                    if (value.length > 1) {
                        const parsedArray = [];

                        value.forEach((item) => {
                            if (typeof item === "number" || typeof item === "boolean") {
                                item = `${item}`;
                            }

                            parsedArray.push(item);
                        });

                        result[key] = parsedArray;
                    }

                    result[key] = `${value[0]}`;
                }
            }, {});
        },
    },
};
