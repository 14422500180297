<template>
    <div v-if="filters">
        <v-section-divider>
            General Filters
        </v-section-divider>

        <div v-if="filters" class="grid grid-cols-1 gap-4 p-default">
            <v-form-select
                v-if="organizations.length > 2"
                v-model="filters.organization_id"
                :options="selectOptions(organizations, 'name', 'organization_id')"
                default-label="All Organizations"
            />

            <v-form-select
                v-model="filters.payout_status_id"
                :options="payoutStatusOptions"
                default-label="All Payout Statuses"
            />
        </div>
    </div>
</template>

<script>
import TableFiltersMixin from "@/mixins/table-filters";
import PayoutStatusService from "@/services/modules/payout-status-service";
import UserService from "@/services/modules/user-service";

export default {
    name: "PayoutTableFilters",
    mixins: [
        TableFiltersMixin,
    ],
    data() {
        return {
            /**
             * @todo Write a description.
             */
            filters: {
                /**
                 * @todo Write a description.
                 */
                payout_status_id: this.$route.query.payout_status_id ?? null,

                /**
                 * @todo Write a description.
                 */
                organization_id: this.$route.query.organization_id ?? null,
            },

            /**
             * @todo Write a description.
             */
            payoutStatusOptions: [],

            /**
             * @todo Write a description.
             */
            organizations: [],
        };
    },
    created() {
        PayoutStatusService.index()
            .then((response) => {
                const parsedResponse = response.data.data
                    .filter((payoutStatus) => (payoutStatus.payout_status_id !== 3))
                    .map((payoutStatus) => ({
                        label: payoutStatus.name,
                        value: payoutStatus.payout_status_id,
                    }));

                this.payoutStatusOptions = [
                    { label: "All Payout Statuses", value: null },
                    ...parsedResponse,
                ];
            });

        UserService.getOrganizations(this.$me.getUser().user_id)
            .then((response) => {
                this.organizations = [
                    {
                        name: "All Organizations",
                        organization_id: null,
                    },
                    ...response.data.data,
                ];
            });
    },
    methods: {
        clearFilters() {
            this.filters = {};
        },
    },
};
</script>
