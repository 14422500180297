<template>
    <v-model-table v-bind="tableDataSource" :columns="columns" :query="query">
        <template v-slot:column_payout_id="{ row }">
            <v-link :to="{ name: 'payouts.show', params: { payout: row.payout_id } }">
                {{ row.payout_id }}
            </v-link>
        </template>

        <template v-slot:column_user="{ row }">
            <v-link :to="{ name: 'users.show.payouts', params: { user: row.for_user_id } }">
                {{ row.user.name }}
            </v-link>
        </template>

        <template v-slot:column_total_amount="{ row }">
            <v-text>
                {{ row.total_amount | numberFormat }}
            </v-text>
        </template>
    </v-model-table>
</template>

<script>
export default {
    name: "PayoutTable",
    props: {
        dataFunction: {
            type: Function,
            required: false,
            default: null,
        },
        query: {
            type: Object,
            required: false,
            default: () => {},
        },
    },
    data() {
        return {
            user: null,
        };
    },
    computed: {
        /**
         * Returns the attributes that should be bound to the table.
         */
        tableDataSource() {
            if (this.dataFunction) {
                return {
                    dataFunction: this.dataFunction,
                };
            }

            if (this.service) {
                return {
                    service: this.service,
                };
            }

            return {
                module: "payoutModule",
            };
        },
        columns() {
            const tableColumns = {
                payout_id: {
                    label: "Payout ID",
                    fullWidth: false,
                },
                payout_status: {
                    label: "Payout Status",
                    formatter: (row) => row.payout_status.name,
                    fullWidth: false,
                },
                user: {
                    label: "User",
                    formatter: (row) => row.user.name,
                    fullWidth: true,
                },
                payout_lines_count: {
                    label: "Payout Lines",
                    formatter: (row) => row.payout_lines_count.toString(),
                    align: "right",
                },
                total_amount: {
                    label: "Total Amount",
                    align: "right",
                },
                created_at: {
                    label: "Created At",
                },
                updated_at: {
                    label: "Updated At",
                },
            };

            return tableColumns;
        },
    },
};
</script>
